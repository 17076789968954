.App {
  text-align: center;
  position: relative;
  min-width: 1024px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}


input.axl-flatpickr-input {
  padding: 0;
}

.Toastify__toast-body {
  white-space: pre-wrap;
}

.event-template {
  height: 100%;
}

.event-template ul {
  list-style: none;
  margin: 2px;
  padding-left: 10px
}

.event-template li:before {
  content: "+";
  padding-right: 4px;
}

.event-template strong {
  font-weight: bold;
}

.horizontal-scroll-actived {
  height: 50px !important;
  overflow-x: hidden !important;
}

.horizontal-scroll-actived > .active {
  border-bottom: 4px solid transparent;
}

.horizontal-scroll-actived li {
  flex-grow: 0;
  width: 120px;
  min-width: 120px;
}

.loading {
  display: grid;
  place-content: center;
  position: fixed;
  inset: 0;
}
