body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #e4e4e4;
  color: #3b3b3b;
}
.main-content {
  padding: 8px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
h1 {
  font-weight: normal;
  margin-bottom: 40px;
  padding-top: 30px;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  font-family: 'AvenirNext';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #bfbfbf;
  color: #bfbfbf;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  font-family: 'AvenirNext';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #bfbfbf;
  color: #bfbfbf;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  font-family: 'AvenirNext';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #bfbfbf;
  color: #bfbfbf;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  font-family: 'AvenirNext';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #bfbfbf;
  color: #bfbfbf;
}
.flatpickr-input {
  height: 45px;
  padding: 3px 16px;
  font-size: 18px;
  border-radius: 3px;
  border: 1px solid rgb(236, 236, 236);
  margin-top: 15px;
  margin-bottom: 15px;
  color: #000;
  font-family: 'AvenirNext';
  width: 100%;
}
.error .flatpickr-input {
  border-color: red !important;
}
.map-tooltip {
  opacity: 0.95;
  padding: 2px;
  margin: 0;
  z-index: 10;
}
.mapboxgl-canvas {
  left: 0;
}
.mapboxgl-ctrl-attrib {
  display: none;
}

.momentumScroll {
  overflow-y: scroll; /* has to be scroll, not auto */
  -webkit-overflow-scrolling: touch;
}

.momentumScrollX {
  overflow-x: scroll; /* has to be scroll, not auto */
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}
.c3-chart-text {
  font-weight: bold;
}

input[id^=react-select] {
  width: 100% !important;
}

.Toastify__toast-container {
  z-index: 99999 !important;
}

.maplibregl-popup {
  z-index: 100;
}

.maplibregl-map.mouse-pointer .maplibregl-canvas-container.maplibregl-interactive {
  cursor: pointer;
}

.maplibregl-map.mouse-move .maplibregl-canvas-container.maplibregl-interactive {
  cursor: move;
}

.maplibregl-map.mouse-add .maplibregl-canvas-container.maplibregl-interactive {
  cursor: crosshair;
}

.maplibregl-map.mouse-move.mode-direct_select .maplibregl-canvas-container.maplibregl-interactive {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.maplibregl-map.mode-direct_select.feature-vertex.mouse-move .maplibregl-canvas-container.maplibregl-interactive {
  cursor: move;
}

.maplibregl-map.mode-direct_select.feature-midpoint.mouse-pointer .maplibregl-canvas-container.maplibregl-interactive {
  cursor: cell;
}

.maplibregl-map.mode-direct_select.feature-feature.mouse-move .maplibregl-canvas-container.maplibregl-interactive {
  cursor: move;
}

.maplibregl-map.mode-static.mouse-pointer  .maplibregl-canvas-container.maplibregl-interactive {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
